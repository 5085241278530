import React from "react";
import { InvoiceForm } from "../../components/InvoiceForm";

export const CreateInvoice: React.FC = () => {
  return (
    <>
      <h1 className="title">Generate invoices</h1>

      <InvoiceForm />
    </>
  );
};
