import { useCallback, useEffect, useState } from 'react';
import { Invoice, InvoiceStatus } from '../../../types/Invoice';
import { enqueueSnackbar } from 'notistack';
import axios from 'axios';
import { Dayjs } from 'dayjs';

export const ITEMS_PER_PAGE_LIMIT = 15;

export const useInvoiceTable = () => {
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [search, setSearch] = useState('');
  const [date, setDate] = useState<Dayjs>();
  const [loading, setLoading] = useState(true);

  const getInvoices = useCallback(
    async (
      {
        page = 1,
        search,
        date,
      }: {
        page?: number;
        search?: string;
        date?: string;
      },
      silent = false,
    ) => {
      try {
        !silent && setLoading(true);
        const {
          data: {
            data: invoices,
            pagination: { totalItems },
          },
        } = await axios.get<{
          data: Invoice[];
          pagination: {
            totalItems: number;
          };
        }>('/api/invoices', { params: { page, limit: ITEMS_PER_PAGE_LIMIT, search, date } });
        setInvoices(invoices);
        setTotalCount(totalItems);
      } catch (e) {
        enqueueSnackbar('Failed to fetch invoices', { variant: 'error' });
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  const handleStatusChange = async (invoiceId: string, status: InvoiceStatus) => {
    try {
      setLoading(true);
      await axios.patch(`/api/invoices/${invoiceId}`, { status });
      getInvoices({ page: currentPage, search, date: date?.format('YYYY-MM') }, true);
    } catch (e) {
      enqueueSnackbar('Failed to change status', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getInvoices({ page: currentPage, search, date: date?.format('YYYY-MM') });
  }, [getInvoices, currentPage, search, date]);

  return {
    invoices,
    loading,
    totalCount,
    currentPage,
    handlePaginationChange: setCurrentPage,
    handleStatusChange,
    setSearch,
    setDate
  };
};
