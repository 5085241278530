import React, { useEffect, useState } from 'react';
import { api } from '../../utils/fetch';
import { LogEntry } from '../../types/LogEntry';
import { enqueueSnackbar } from 'notistack';
import { Button, DatePicker, Form, InputNumber, Select, Upload, UploadFile } from 'antd';
import { usePaymentSections, useResellers } from '../../hooks';
import { UploadOutlined } from '@ant-design/icons';
import { useForm } from 'antd/es/form/Form';
import dayjs, { Dayjs } from 'dayjs';
import { useInterbankRates } from '../../hooks/useInterbankRates';
import { disableFutureDates } from './utils';

interface FormValues {
  upload: UploadFile[];
  date: Dayjs;
  reseller: string;
  paymentSection: string;
  'usd-uah': string;
  'eur-uah': string;
  'usd-eur': string;
  'eur-usd': string;
}

export const InvoiceForm: React.FC = () => {
  const [form] = useForm();
  const { resellerOptions, loading: resellersLoading } = useResellers();
  const { paymentSectionOptions, loading: paymentSectionsLoading } = usePaymentSections();
  const { getInterbankRates } = useInterbankRates();

  const [isLoading, setIsLoading] = useState(false);

  const handleFinish = async (values: FormValues) => {
    const usages = values.upload?.[0]?.originFileObj;

    setIsLoading(true);

    const formData = new FormData();
    const rates = {
      'usd-uah': values['usd-uah'],
      'eur-uah': values['eur-uah'],
      'usd-eur': values['usd-eur'],
      'eur-usd': values['eur-usd'],
    };

    formData.append('usages', usages as File);
    formData.append('rates', JSON.stringify(rates));
    formData.append('date', dayjs(values.date).format('YYYY-MM-DD'));
    formData.append('resellerId', values.reseller);
    formData.append('paymentSectionId', values.paymentSection);

    try {
      await api.sendFiles<LogEntry[]>('/invoices/from-csv', formData);
      enqueueSnackbar('Success', { variant: 'success' });
    } catch (e) {
      console.error(e);
      const err = e as Error;
      enqueueSnackbar(`Download failed: ${err.message}`, { variant: 'error' });
    }

    setIsLoading(false);
  };

  const updateFormRates = async (invoiceDate?: Dayjs) => {
    const ratesFromApi = await getInterbankRates(invoiceDate?.format('YYYY-MM-DD'));
    form.setFields(Object.entries(ratesFromApi).map(([name, value]) => ({ name, value })));
  };

  useEffect(() => {
    updateFormRates();
  }, []);

  return (
    <>
      <Form form={form} layout="vertical" style={{ maxWidth: 600 }} onFinish={handleFinish} requiredMark={false}>
        <Form.Item
          name="upload"
          label="Upload File"
          valuePropName="fileList"
          getValueFromEvent={(e) => e?.fileList.slice(-1) || []} // Ensure only one file
          rules={[
            {
              required: true,
              message: 'Please upload a file!',
            },
          ]}
        >
          <Upload
            beforeUpload={() => false} // prevent automatic upload
            maxCount={1}
            accept=".csv"
          >
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
        </Form.Item>
        <Form.Item label="Date of invoices" name="date" rules={[{ required: true, message: 'Required' }]}>
          <DatePicker disabledDate={disableFutureDates} onChange={updateFormRates} />
        </Form.Item>
        <Form.Item
          style={{ marginBottom: 10, width: 250 }}
          label="Rates"
          name="usd-uah"
          rules={[{ required: true, message: 'Required' }]}
        >
          <InputNumber addonBefore="1 USD =" addonAfter="UAH" />
        </Form.Item>
        <Form.Item
          style={{ marginBottom: 10, width: 250 }}
          name="eur-uah"
          rules={[{ required: true, message: 'Required' }]}
        >
          <InputNumber addonBefore="1 EUR =" addonAfter="UAH" />
        </Form.Item>
        <Form.Item
          style={{ marginBottom: 10, width: 250 }}
          name="usd-eur"
          rules={[{ required: true, message: 'Required' }]}
        >
          <InputNumber addonBefore="1 USD =" addonAfter="EUR" />
        </Form.Item>
        <Form.Item style={{ width: 250 }} name="eur-usd" rules={[{ required: true, message: 'Required' }]}>
          <InputNumber addonBefore="1 EUR =" addonAfter="USD" />
        </Form.Item>
        <Form.Item label="Reseller" name="reseller" rules={[{ required: true, message: 'Required' }]}>
          <Select
            placeholder="Select reseller"
            style={{ width: 200 }}
            options={resellerOptions}
            loading={resellersLoading}
          />
        </Form.Item>
        <Form.Item label="Payment section" name="paymentSection" rules={[{ required: true, message: 'Required' }]}>
          <Select
            placeholder="Select payment section"
            style={{ width: 200 }}
            options={paymentSectionOptions}
            loading={paymentSectionsLoading}
          />
        </Form.Item>
        <Form.Item>
          <Button loading={isLoading} type="primary" htmlType="submit">
            Generate invoices
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
