import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ProductsPricesTable } from '../../components/ProductsPricesTable';
import { DatePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { Loader } from '../../components/Loader';
import { api } from '../../utils/fetch';
import { enqueueSnackbar } from 'notistack';
import { ProductsPrice } from '../../types/ProductPrice';
import axios from 'axios';

export const ProductsPricesPage: React.FC = () => {
  const [date, setDate] = useState<Dayjs>();
  const [isSync, setIsSync] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [productsPrices, setProductsPrices] = useState<ProductsPrice[]>([]);

  const handleDateChange = (date: Dayjs) => {
    setDate(date);
    if (date) {
      sessionStorage.setItem('date', date.toJSON());
    } else {
      sessionStorage.removeItem('date');
    }
  };

  const disabledDate = (current: Dayjs | null): boolean => {
    const today = dayjs();

    if (!current) {
      return false;
    }

    // Disable past years
    return current.year() < today.year();
  };

  const handlePriceDelete = async (productsPriceItem: ProductsPrice) => {
    try {
      await api.delete('/prices/' + productsPriceItem.priceId);
      await getProductsPricesFromAPI();
    } catch (e) {
      const err = e as Error;
      enqueueSnackbar(`Failed to delete ${productsPriceItem.product}: ${err.message}`, { variant: 'error' });
    }
  };

  const getProductsPricesFromAPI = useCallback(async () => {
    setIsLoading(true);

    try {
      const { data } = await axios.get<{
        rows: ProductsPrice[];
      }>('/api/prices', { params: { date: date ? dayjs(date).format('YYYY-MM') : undefined } });
      if (date && !data.rows.length) {
        enqueueSnackbar('No data for this month', { variant: 'error' });
      }
      setProductsPrices(data.rows);
    } catch (e) {
      const err = e as Error;
      enqueueSnackbar(`Error while loading products: ${err.message}`, { variant: 'error' });
    }

    setIsLoading(false);
  }, [date]);

  const handleSync = async () => {
    setIsSync(true);
    try {
      const res = await api.post<{ message: string }>(`/products/sync`, {});
      await getProductsPricesFromAPI()
      enqueueSnackbar(`Success: ${res.message}`, { variant: 'success' });
      setIsSync(false);
    } catch (e) {
      const err = e as Error;
      enqueueSnackbar(`Failed to sync products: ${err.message}`, { variant: 'error' });
      setIsSync(false);
    }
  };

  useEffect(() => {
    getProductsPricesFromAPI();
  }, [getProductsPricesFromAPI]);

  useEffect(() => {
    const initialDate = sessionStorage.getItem('date');
    if (initialDate) {
      setDate(dayjs(new Date(initialDate)));
    }
  }, []);

  return (
    <>
      <div className="is-flex is-justify-content-space-between mb-3">
        <h1 className="title m-0">Products prices</h1>

        <div className="is-flex is-align-items-center" style={{ gap: '12px' }}>
          <DatePicker picker="month" value={date} onChange={handleDateChange} disabledDate={disabledDate} />
          <button onClick={handleSync} className="button ml-3 is-danger">
            {isSync ? <Loader /> : 'Sync'}
          </button>
          <Link to="/products-prices/from-csv" className="button is-warning">
            PriceList from Google CSV
          </Link>
          <Link to="/products-prices/create" className="button is-primary">
            Create product price
          </Link>
        </div>
      </div>
      {isLoading && <Loader />}
      <ProductsPricesTable productsPrices={productsPrices} onPriceDelete={handlePriceDelete} />
    </>
  );
};
