import React, { useEffect, useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import { PaymentSection, PaymentSectionDataStr } from '../../types/PaymentSection';
import { Link } from 'react-router-dom';
import { Loader } from '../Loader';
import { api } from '../../utils/fetch';
import { Table } from 'antd';
import { enqueueSnackbar } from 'notistack';
import classNames from 'classnames';
import { DeletePrompt } from '../DeletePrompt';

export const PaymentSectionsTable: React.FC = () => {
  const columns: ColumnsType<PaymentSectionDataStr> = [
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
    },
    {
      title: 'Language',
      dataIndex: 'language',
      key: 'language',
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      key: 'currency',
    },
    {
      title: 'Data',
      dataIndex: 'data',
      key: 'data',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, item) => (
        <td className="has-text-centered">
          <Link to={`/payment-sections/${item.id}`}>Edit</Link>
          <br />
          <button
            className={classNames('button is-ghost has-text-danger', { 'is-loading': deletingSection })}
            onClick={() => setDeletingSection(item)}
          >
            Delete
          </button>
        </td>
      ),
    },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [deletingSection, setDeletingSection] = useState<PaymentSectionDataStr | null>(null);
  const [paymentSections, setPaymentSections] = useState<PaymentSectionDataStr[] | null>(null);

  async function onDeleteHandler() {
    if (!deletingSection) {
      return;
    }

    try {
      await api.delete('/payment-sections/' + deletingSection.id);
      await getPaymentSectionsFromAPI();
      enqueueSnackbar('Payment section deleted', { variant: 'success' });
    } catch (e) {
      console.error(e);
      const err = e as Error;
      enqueueSnackbar(`Failed to delete payment: ${err.message}`, { variant: 'error' });
    }

    setDeletingSection(null);
  }

  const getPaymentSectionsFromAPI = async () => {
    setIsLoading(true);

    try {
      const response = await api.get<PaymentSection[]>('/payment-sections');
      const paymentSectionData: PaymentSectionDataStr[] = response.map((section) => ({
        ...section,
        data: section.data?.map((dataSection) => `${dataSection.label}: ${dataSection.value}`).join('\n'),
      }));

      setPaymentSections(paymentSectionData);
    } catch (e) {
      const err = e as Error;
      enqueueSnackbar(`Error while loading payment sections: ${err.message}`, { variant: 'error' });
    }

    setIsLoading(false);
  };

  useEffect(() => {
    getPaymentSectionsFromAPI();
  }, []);

  return (
    <>
      {isLoading && <Loader />}

      {deletingSection && (
        <DeletePrompt
          title="Are you sure?"
          buttons={
            <>
              <button onClick={onDeleteHandler} className="button mr-3 is-danger">
                Delete
              </button>

              <button onClick={() => setDeletingSection(null)} className="button is-link">
                Close
              </button>
            </>
          }
        />
      )}

      {!isLoading && paymentSections && (
        <Table rowKey="id" dataSource={paymentSections} columns={columns} pagination={false} />
      )}
    </>
  );
};
