type RequestMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

async function request<T>(
  url: string,
  method: RequestMethod = 'GET',
  data: any = null,
  contentType: 'json' | 'formData' = 'json'
): Promise<T> {
  const options: RequestInit = { method };

  if (data && contentType === 'json') {
    options.body = JSON.stringify(data);
    options.headers = {
      'Content-Type': 'application/json; charset=UTF-8',
    };
    // options.credentials = 'include';
  } else if (data && contentType === 'formData') {
    options.body = data;
  }

  const response = await fetch('/api' + url, options);

  const resContentType = response.headers.get('content-type');

  const isJson = resContentType && resContentType.includes('application/json');

  if (!response.ok) {
    if (isJson) {
      const jsonErr = await response.json();

      if (jsonErr.message) {
        throw new Error(jsonErr.message);
      }
    }

    const responseErr = await response.text();
    if (responseErr) {
      throw new Error(responseErr);
    }

    throw new Error('Request failed with no message');
  }

  if (response.status === 204 || !isJson) {
    return response.text() as T;
  }

  return response.json() as T;
}

export const api = {
  get: <T>(url: string) => request<T>(url),
  post: <T>(url: string, data: any) => request<T>(url, 'POST', data),
  put: <T>(url: string, data: any) => request<T>(url, 'PUT', data),
  patch: <T>(url: string, data: any) => request<T>(url, 'PATCH', data),
  delete: (url: string) => request(url, 'DELETE'),
  sendFiles: <T>(url: string, formData: FormData) => request<T>(url, 'POST', formData, 'formData'),
};
