import React, { FC, useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';
import { Table } from 'antd';
import { ProductsPrice } from '../../types/ProductPrice';
import { DeletePrompt } from '../DeletePrompt';
import classNames from 'classnames';

interface Props {
  productsPrices: ProductsPrice[];
  onPriceDelete: (price: ProductsPrice) => Promise<void>;
}

export const ProductsPricesTable: FC<Props> = ({ productsPrices, onPriceDelete }) => {
  const [productPriceDeleteList, setProductPriceDeleteList] = useState<ProductsPrice | null>(null);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const handlePriceDelete = async () => {
    if (!productPriceDeleteList) return;
    setDeleteLoading(true);
    await onPriceDelete(productPriceDeleteList);
    setProductPriceDeleteList(null);
    setDeleteLoading(false);
  };

  const columns: ColumnsType<ProductsPrice> = [
    {
      title: 'Product',
      dataIndex: 'product',
      key: 'product',
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      key: 'currency',
    },
    {
      title: 'Standard price, license',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'Discount, %',
      dataIndex: 'discountPercent',
      key: 'discountPercent',
    },
    {
      title: 'Type of license',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Actions',
      dataIndex: 'Actions',
      key: 'Actions',
      render: (_, item) => (
        <td className="has-text-centered">
          <Link to={`/products-prices/${item.priceId}`}>Edit</Link>
          <br />
          <button className="button is-ghost has-text-danger" onClick={() => setProductPriceDeleteList(item)}>
            Delete
          </button>
        </td>
      ),
    },
  ];

  return (
    <>
      {productPriceDeleteList && (
        <DeletePrompt
          title="Are you sure?"
          buttons={
            <>
              <button
                onClick={handlePriceDelete}
                className={classNames('button mr-3 is-danger', { loader: deleteLoading })}
              >
                Delete
              </button>

              <button onClick={() => setProductPriceDeleteList(null)} className="button is-link">
                Close
              </button>
            </>
          }
        />
      )}
      <Table rowKey="id" dataSource={productsPrices} columns={columns} pagination={false} />
    </>
  );
};
