import React, { FormEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Cost } from '../../types/Cost';
import { api } from '../../utils/fetch';
import { CurrencySelect } from '../ClientForm/CurrencySelect';
import { Loader } from '../Loader';
import { enqueueSnackbar } from 'notistack';

export const CostForm: React.FC = () => {
  const { costId } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [currentCost, setCurrentCost] = useState<Cost>({
    id: 0,
    domain: '',
    product: '',
    clientCost: 0,
    vatPercent: 0,
    discountPercent: 0,
    currency: 'EUR',
  });

  useEffect(() => {
    const getCostFromAPI = async (id: string) => {
      setIsLoading(true);

      try {
        const res = await api.get<Cost>('/costs/' + id);

        setCurrentCost(res);
      } catch (e) {
        console.error(e);
        const err = e as Error;
        enqueueSnackbar(`Failed to get costs: ${err.message}`, { variant: 'error' });
      }

      setIsLoading(false);
    };

    if (costId && costId !== 'new') {
      getCostFromAPI(costId);
    }
  }, []);

  const formSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSaving(true);
    try {
      if (costId === 'new') {
        await api.post('/costs', currentCost);
        enqueueSnackbar('Cost saved!', { variant: 'success' });
      } else {
        await api.put('/costs/' + costId, currentCost);
        enqueueSnackbar('Cost updated!', { variant: 'success' });
      }
    } catch (e) {
      console.error(e);
      const err = e as Error;
      enqueueSnackbar(`Cost saving failed: ${err.message}`, { variant: 'error' });
    }
    setIsSaving(false);
  };

  const { domain, product, clientCost, currency, vatPercent, discountPercent } = currentCost;

  return isLoading ? (
    <Loader />
  ) : (
    <form onSubmit={formSubmit} className="container">
      <div className="field">
        <label className="label">Domain:</label>
        <div className="control">
          <input
            className="input"
            type="text"
            placeholder="Domain input"
            value={domain}
            onChange={(e) => setCurrentCost((prevState) => ({ ...prevState, domain: e.target.value }))}
          />
        </div>
      </div>

      <div className="field">
        <label className="label">Product:</label>
        <div className="control">
          <input
            className="input"
            type="text"
            placeholder="Product input"
            value={product}
            onChange={(e) => setCurrentCost((prevState) => ({ ...prevState, product: e.target.value }))}
          />
        </div>
      </div>

      <div className="field">
        <label className="label">Currency</label>
        <div className="control">
          <div className="select">
            <CurrencySelect
              name="currency"
              value={currency}
              onChange={(e) => {
                setCurrentCost((prevState) => ({ ...prevState, currency: e.target.value as typeof currency }));
              }}
            />
          </div>
        </div>
      </div>

      <div className="field">
        <label className="label">Client cost:</label>
        <div className="control">
          <input
            className="input"
            type="number"
            placeholder="Client cost input"
            value={clientCost}
            onChange={(e) => setCurrentCost((prevState) => ({ ...prevState, clientCost: +e.target.value }))}
          />
        </div>
      </div>

      <div className="field">
        <label className="label">Vat percent:</label>
        <div className="control">
          <input
            className="input"
            type="number"
            placeholder="Vat percent input"
            value={vatPercent}
            onChange={(e) => setCurrentCost((prevState) => ({ ...prevState, vatPercent: +e.target.value }))}
          />
        </div>
      </div>

      <div className="field">
        <label className="label">Discount percent:</label>
        <div className="control">
          <input
            className="input"
            type="number"
            placeholder="Discount percent input"
            value={discountPercent}
            onChange={(e) => setCurrentCost((prevState) => ({ ...prevState, discountPercent: +e.target.value }))}
          />
        </div>
      </div>

      <div className="field is-grouped">
        <div className="control">
          <button className="button is-link">{isSaving ? <Loader /> : 'Save cost'}</button>
        </div>
      </div>
    </form>
  );
};
