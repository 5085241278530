import { FC, memo } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import cn from 'classnames';

type NavbarItemsProps = {
  to: string;
  text: string;
  icon?: any;
};

const getClassName: NavLinkProps['className'] = ({ isActive }) =>
  cn('navbar-item', isActive && 'has-background-info-light has-text-info-dark');

export const NavbarItem: FC<NavbarItemsProps> = memo(({ to, text, icon }) => (
  <NavLink style={{ display: 'flex', gap: '6px' }} className={getClassName} to={to}>
    {icon && <img style={{ height: '20px' }} src={icon} alt="icon" height="20" width="20" />}
    {text}
  </NavLink>
));
