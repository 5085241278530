import { useEffect, useState } from 'react';
import { ProductsPrice } from '../../types/ProductPrice';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';

export const useProductPrice = (id?: string) => {
  const [loading, setLoading] = useState(false);
  const [defaultProductPrice, setCurrentProductPrice] = useState<ProductsPrice>();

  useEffect(() => {
    (async () => {
      if (!id) return;

      setLoading(true);
      try {
        const { data } = await axios.get<ProductsPrice>('/api/prices/' + id);
        setCurrentProductPrice(data);
      } catch (e) {
        if (axios.isAxiosError(e)) {
          enqueueSnackbar(`Error while loading product: ${e.response?.data.message}`, { variant: 'error' });
        }
      } finally {
        setLoading(false);
      }
    })();
  }, [id]);

  return {
    defaultProductPrice,
    loading,
  };
};
