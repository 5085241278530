import React, { useState } from 'react';
import { ProductPriceForm } from '../../components/ProductPriceForm';
import { CreateProductPriceProps } from '../../types/ProductPrice';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { FormData } from '../../components/ProductPriceForm/types';
import dayjs from 'dayjs';

export const CreateProductPrice: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const navigate  = useNavigate()

  const handleFinish = async (values: FormData) => {
    const [validFrom, validTo] = values.period;

    try {
      setLoading(true);
      const {
        data: { id: productId },
      } = await axios.post<{ id: string }>('/api/products', { name: values.product });

      const productPrice: CreateProductPriceProps = {
        ...values,
        validFrom: dayjs(validFrom).startOf('month').toJSON(),
        validTo: dayjs(validTo).endOf('month').toJSON(),
        productId,
      };
      await axios.post('/api/prices', productPrice);
      enqueueSnackbar('Product created!', { variant: 'success' });
      navigate('/products-prices');
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const errorMessage = 'errors' in e.response?.data ? e.response?.data.errors[0] : e.response?.data.message;
        enqueueSnackbar(`Failed to create product price: ${errorMessage}`, { variant: 'error' });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h1 className="title">Create Product price</h1>

      <ProductPriceForm onFinish={handleFinish} loading={loading} />
    </div>
  );
};
