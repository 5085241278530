import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Reseller } from '../../types/Reseller';
import { api } from '../../utils/fetch';
import { ResellerForm } from '../../components/ResellerForm';
import { Loader } from '../../components/Loader';
import { enqueueSnackbar } from 'notistack';

export const ResellerPage: React.FC = () => {
  const { resellerId } = useParams();

  const [reseller, setReseller] = useState<Reseller | null>(null);

  const [isLoading, setIsLoading] = useState(false);

  const getResellerFromAPI = async () => {
    setIsLoading(true);

    try {
      const response = await api.get<Reseller>('/resellers/' + resellerId);

      setReseller(response);
    } catch (e) {
      const err = e as Error;
      enqueueSnackbar(`Failed to get reseller: ${err.message}`, { variant: 'error' });
    }

    setIsLoading(false);
  };

  useEffect(() => {
    getResellerFromAPI();
  }, []);

  return (
    <>
      <h1 className="title">Reseller page</h1>

      {reseller && <ResellerForm resellerItem={reseller} isNewReseller={false} />}

      {isLoading && <Loader />}
    </>
  );
};
