import React, { useEffect, useState } from 'react';
import { Loader } from '../../components/Loader';
import { PaymentSectionForm } from '../../components/PaymentSectionForm';
import { useParams } from 'react-router-dom';
import { PaymentSection } from '../../types/PaymentSection';
import { api } from '../../utils/fetch';
import { enqueueSnackbar } from 'notistack';

export const PaymentSectionPage: React.FC = () => {
  const { paymentSectionId } = useParams();

  const [paymentSection, setPaymentSection] = useState<PaymentSection | null>(null);

  const [isLoading, setIsLoading] = useState(false);

  const getPaymentSectionFromAPI = async () => {
    setIsLoading(true);

    try {
      const response = await api.get<PaymentSection>('/payment-sections/' + paymentSectionId);

      setPaymentSection(response);
    } catch (e) {
      const err = e as Error;
      enqueueSnackbar(`Error while loading payment section: ${err.message}`, { variant: 'error' });
    }

    setIsLoading(false);
  };

  useEffect(() => {
    getPaymentSectionFromAPI();
  }, []);

  return (
    <>
      <h1 className="title">Payment section page</h1>

      {paymentSection && <PaymentSectionForm paymentSectionItem={paymentSection} isNewPaymentSection={false} />}

      {isLoading && <Loader />}
    </>
  );
};
