import React from 'react';
import { ClientForm } from '../../components/ClientForm';

export const CreateClient: React.FC = () => {
  return (
    <>
      <h1 className="title">Create a new client</h1>

      <ClientForm isNewClient={true} />
    </>
  );
};
