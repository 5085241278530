import React from 'react';
import { InvoiceTable } from './components';
import { useInvoiceTable } from './hooks';
import { SearchTextField } from '../../components/SearchTextField';
import { DatePicker } from 'antd';

export const Invoices: React.FC = () => {
  const { invoices, loading, totalCount, currentPage, handlePaginationChange, handleStatusChange, setDate, setSearch } =
    useInvoiceTable();

  return (
    <>
      <h1 className="title">Invoices</h1>
      <div style={{ display: 'flex', alignItems: 'center', gap: 20, marginBottom: 12 }}>
        <div style={{ width: '100%' }}>
          <SearchTextField onSearch={setSearch} />
        </div>
        <DatePicker picker="month" onChange={setDate} allowClear />
      </div>
      <InvoiceTable
        invoices={invoices}
        loading={loading}
        currentPage={currentPage}
        totalCount={totalCount}
        onPaginationChange={handlePaginationChange}
        onStatusChange={handleStatusChange}
      />
    </>
  );
};
