import { useEffect, useMemo, useReducer } from 'react';
import { getInitialState, loadPaymentSections, reducer } from '../pages/PaymentSectionsListPage/reducer';

export const usePaymentSections = () => {
  const [state, dispatch] = useReducer(reducer, undefined, getInitialState);

  useEffect(() => {
    void loadPaymentSections({ dispatch });
  }, []);

  const paymentSections = useMemo(() => (state.mode === 'ready' ? state.paymentSections : []), [state]);

  const paymentSectionOptions = useMemo(
    () =>
      paymentSections.length > 0
        ? paymentSections.map((paymentSection) => ({
          label: paymentSection.country,
          value: paymentSection.id,
        }))
        : [],
    [paymentSections],
  );

  return {
    paymentSections,
    paymentSectionOptions,
    loading: state.mode === 'loading',
  };
};
