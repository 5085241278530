import React, { FC, useEffect, useMemo, useReducer, useState } from 'react';
import { Link } from 'react-router-dom';
import { DeletePrompt } from '../../components/DeletePrompt';
import { api } from '../../utils/fetch';
import { ClientsTable } from './ClientsTable';
import { reducer, getInitialState, loadClients } from './reducer';
import { enqueueSnackbar } from 'notistack';
import { Loader } from '../../components/Loader';
import { SearchTextField } from '../../components/SearchTextField';

export const ClientsPage: FC = () => {
  const [state, dispatch] = useReducer(reducer, undefined, getInitialState);
  const [idToDel, setIdToDel] = useState<number | null>(null);
  const [isSync, setIsSync] = useState(false);

  const [searchBy, setSearchBy] = useState('');

  const openPromptOnDel = (id: number) => setIdToDel(id);

  const handleDeleteClick = async () => {
    try {
      await api.delete(`/clients/${idToDel}`);
      await loadClients({ dispatch });
      setIdToDel(null);
      enqueueSnackbar('Client deleted!', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar('Failed to delete client', { variant: 'error' });
    }
  };

  const handleSync = async () => {
    setIsSync(true);
    try {
      const res = await api.post<{ message: string }>(`/clients/sync`, {});
      await loadClients({ dispatch });
      enqueueSnackbar(`Success: ${res.message}`, { variant: 'success' });
      setIsSync(false);
    } catch (e) {
      const err = e as Error;
      enqueueSnackbar(`Failed to sync clients: ${err.message}`, { variant: 'error' });
      setIsSync(false);
    }
  };

  useEffect(() => {
    void loadClients({ dispatch });
  }, []);

  const filteredClients = useMemo(() => {
    if (state.mode === 'ready') {
      return state.clients?.filter(
        (client) =>
          client.address?.toLowerCase().match(searchBy.toLowerCase()) ||
          client.billFrom?.toLowerCase().match(searchBy.toLowerCase()) ||
          client.domains?.some((domain) => domain.toLowerCase().match(searchBy.toLowerCase())) ||
          client.email?.some((email) => email.toLowerCase().match(searchBy.toLowerCase())),
      );
    }
    return [];
  }, [state, searchBy]);

  if (state.mode === 'initial' || state.mode === 'loading') {
    return <h1 className="title">Loading clients...</h1>;
  }

  if (state.mode === 'error') {
    return (
      <>
        <h1 className="title">Clients</h1>
        <p>There was an error with loading clients, please refresh the page.</p>
        <p>{state.error}</p>
      </>
    );
  }

  return (
    <>
      {idToDel && (
        <DeletePrompt
          title="Are you sure?"
          buttons={
            <>
              <button onClick={handleDeleteClick} className="button mr-3 is-danger">
                Delete
              </button>

              <button onClick={() => setIdToDel(null)} className="button is-link">
                Close
              </button>
            </>
          }
        />
      )}

      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <h1 className="title">Clients</h1>
        <div>
          <Link to="/clients/new" className="button is-primary">
            Create client
          </Link>
          <button onClick={handleSync} className="button ml-3 is-danger">
            {isSync ? <Loader /> : 'Sync'}
          </button>
        </div>
      </div>

      <SearchTextField
        searchBy={searchBy}
        onSearch={setSearchBy}
        onResetSearchBy={() => setSearchBy('')}
      />

      <ClientsTable clients={filteredClients} onDeleteClick={openPromptOnDel} />
    </>
  );
};
