import { useWindowSize } from '@uidotdev/usehooks';
import { useEffect } from 'react';

export const useZoom = () => {
  const { width } = useWindowSize();

  useEffect(() => {
    if (!width) return;
    const zoomFactor = width / 1344;
    if (zoomFactor > 1) {
      (document.body.style as any).zoom = 1;
      return;
    }
    (document.body.style as any).zoom = Number(zoomFactor.toFixed(2));
  }, [width]);
}
