import { ChangeEventHandler, FC, memo } from 'react';

type CurrencySelectProps = {
  name: string;
  value?: string;
  onChange: ChangeEventHandler<HTMLSelectElement>;
};

export const CurrencySelect: FC<CurrencySelectProps> = memo(({ name, value, onChange }) => (
  <select name={name} value={value} onChange={onChange}>
    <option value="USD">USD</option>
    <option value="UAH">UAH</option>
    <option value="EUR">EUR</option>
  </select>
));
