import React, { useState } from 'react';
import { api } from '../../../utils/fetch';
import { Loader } from '../../Loader';
import { useParams } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';

type Props = {
  closeModal: () => void;
  balances: { amount: number; currency: string }[];
  currency: 'EUR' | 'USD' | 'UAH';
};

export const EditUserBalanceModal: React.FC<Props> = ({ closeModal, balances, currency }) => {
  const { userId } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [amount, setAmount] = useState(0);

  const currentBalance = balances.find((balance) => balance.currency === currency);

  const editUserBalanceOnAPI = async () => {
    setIsLoading(true);
    try {
      await api.post('/payments/edit-balance/' + userId, { amount });

      enqueueSnackbar('Success', { variant: 'success' });
    } catch (e) {
      console.error(e);
      const err = e as Error;
      enqueueSnackbar(`Failed to save balance: ${err.message}`, { variant: 'error' });
    }
    setIsLoading(false);
  };

  return (
    <div className="modal is-active">
      <div className="modal-background" onClick={closeModal}></div>
      <div className="modal-content">
        <h1 className="title has-text-white">Edit user's balance</h1>

        <div className="box">
          <label className="label">Positive or negative amount</label>

          <div className="field has-addons">
            <p className="control">
              <input
                onChange={(e) => setAmount(+e.target.value)}
                value={amount}
                className="input"
                type="number"
                placeholder="Amount of money"
              />
            </p>
            <p className="control">
              <span className="button is-static">=</span>
            </p>
            <p className="control">
              <input className="input" type="text" disabled value={(currentBalance?.amount || 0) + amount} />
            </p>
          </div>

          <div className="notification is-link is-light">
            <span>NOTE: Now you are editing balance in current active user's currency!</span>
            <br />
            <strong>Current currency: {currency}</strong>
          </div>

          <button onClick={() => editUserBalanceOnAPI()} className="button is-success">
            {isLoading ? <Loader /> : 'Save'}
          </button>
        </div>
      </div>
    </div>
  );
};
