import type { Dispatch, Reducer } from 'react';
import type { PaymentSection } from '../../types/PaymentSection';
import { api } from '../../utils/fetch';
import { tryCatch } from '../../utils/tryCatch';

export type State =
  | { mode: 'initial' }
  | { mode: 'ready'; paymentSections: PaymentSection[] }
  | { mode: 'loading' }
  | { mode: 'error'; error: string };

type Action =
  | { type: 'loadStart' }
  | { type: 'loadSuccess'; paymentSections: PaymentSection[] }
  | { type: 'loadError'; error: Error };

export const getInitialState = (): State => ({
  mode: 'initial',
});

export const reducer: Reducer<State, Action> = (state, action) => {
  if (action.type === 'loadStart') {
    return { mode: 'loading' };
  }
  if (action.type === 'loadSuccess') {
    return { mode: 'ready', paymentSections: action.paymentSections };
  }
  if (action.type === 'loadError') {
    return { mode: 'error', error: action.error.message };
  }
  return state;
};

type LoadPaymentSectionsParams = {
  dispatch: Dispatch<Action>;
};

export const loadPaymentSections = async (params: LoadPaymentSectionsParams) => {
  const { dispatch } = params;
  dispatch({ type: 'loadStart' });
  const [error, newPaymentSections] = await tryCatch(() => api.get<PaymentSection[]>('/payment-sections'));
  if (error) {
    return dispatch({ type: 'loadError', error });
  }
  dispatch({ type: 'loadSuccess', paymentSections: newPaymentSections });
};
