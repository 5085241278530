import { useEffect, useMemo, useReducer } from 'react';
import { getInitialState, loadResellers, reducer } from '../pages/ResellersPage/reducer';

export const useResellers = () => {
  const [state, dispatch] = useReducer(reducer, undefined, getInitialState);

  useEffect(() => {
    void loadResellers({ dispatch });
  }, []);

  const resellers = useMemo(() => (state.mode === 'ready' ? state.resellers : []), [state]);

  const resellerOptions = useMemo(
    () =>
      resellers.length > 0
        ? resellers.map((reseller) => ({
            label: reseller.reseller,
            value: reseller.id,
          }))
        : [],
    [resellers],
  );

  return {
    resellers,
    resellerOptions,
    loading: state.mode === 'loading',
  };
};
