import React, { ChangeEventHandler, useState } from 'react';
import { api } from '../../utils/fetch';
import { Loader } from '../Loader';
import { CurrencySelect } from './CurrencySelect';
import { enqueueSnackbar } from 'notistack';

type Props = {
  emails: string[];
  currency: string;
  amount: number;
  closeModal: () => void;
};

export const CreatePaymentLinkModal: React.FC<Props> = ({ emails, closeModal, currency, amount }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [paymentData, setPaymentData] = useState({
    amount,
    currency,
    description: 'Description is always the same but we need to know it)',
    email: emails[0],
  });
  const [paymentLink, setPaymentLink] = useState<null | string>(null);

  const isEmailListed = (): boolean => {
    return emails.includes(paymentData.email);
  };
  const createPaymentLinkOnAPI = async () => {
    if (!isEmailListed()) {
      enqueueSnackbar(`Email should be from client emails list: ${emails.join(', ')}`, {
        variant: 'error',
        autoHideDuration: 6000,
      });
      return;
    }
    setIsLoading(true);
    try {
      const res = await api.post<{ paymentLink: string }>('/payments/create-link', paymentData);

      setPaymentLink(res.paymentLink);
    } catch (e) {
      console.error(e);
      const err = e as Error;
      enqueueSnackbar(`Failed to create link: ${err.message}`, { variant: 'error' });
    }
    setIsLoading(false);
  };

  const handleChange: ChangeEventHandler<HTMLInputElement | HTMLSelectElement> = (e) => {
    const { name, value } = e.target;
    setPaymentData((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <div className="modal is-active">
      <div className="modal-background" onClick={closeModal}></div>
      <div className="modal-content">
        <button className="button mr-3 is-danger is-pulled-right is-medium" onClick={closeModal}>
          &times;
        </button>
        <h1 className="title has-text-white">Create Payment Link</h1>

        <div className="box">
          <label className="label">Currency and amount</label>

          <div className="field has-addons">
            <p className="control">
              <span className="select">
                <CurrencySelect name="currency" value={paymentData.currency} onChange={handleChange} />
              </span>
            </p>

            <p className="control">
              <input
                onChange={(e) => {
                  setPaymentData((prevState) => ({
                    ...prevState,
                    amount: +e.target.value,
                  }));
                }}
                value={paymentData.amount ? paymentData.amount : undefined}
                className="input"
                type="number"
                placeholder="Amount of money"
              />
            </p>
          </div>

          <div className="field">
            <input
              value={paymentData.email}
              onChange={(e) => {
                setPaymentData((prevState) => ({
                  ...prevState,
                  email: e.target.value,
                }));
              }}
              className="input"
              type="text"
              placeholder="Customer email"
            />
          </div>

          <button onClick={() => createPaymentLinkOnAPI()} className="button is-success">
            {isLoading ? <Loader /> : 'Create link'}
          </button>
        </div>

        {paymentLink && (
          <div className="box">
            <h2 className="title">Copy your payment link:</h2>
            <div className="notification is-primary">
              <a target="_blank" href={paymentLink}>
                {paymentLink}
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
