type GetTotalParams = {
  cost?: number;
  discountPercent?: number;
  sbFeePercent?: number;
  referralFee?: number;
};

export const getTotal = (params: GetTotalParams) => {
  const cost = params.cost || 0;

  const discountPercent = params.discountPercent || 0;
  const sbFeePercent = params.sbFeePercent || 0;
  const discount = cost * (discountPercent / 100) * (1 - sbFeePercent / 100);

  const referralFee = params.referralFee || 0;

  return (cost - discount - referralFee).toFixed(2);
};
