import React, { useEffect, useState } from 'react';
import crossIcon from '../../media/xmark-solid.svg';

type Props = {
  searchBy?: string;
  onSearch: (value: string) => void;
  onResetSearchBy?: () => void;
};

export const SearchTextField: React.FC<Props> = ({ searchBy, onSearch, onResetSearchBy }) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    const t = setTimeout(() => {
      onSearch(value);
    }, 500);

    return () => {
      clearTimeout(t);
    };
  }, [onSearch, value]);

  const handleClearClick = () => {
    setValue('');
    onResetSearchBy?.();
  };

  useEffect(() => {
    if (!searchBy) return;
    setValue(searchBy);
  }, [searchBy]);

  return (
    <div className="field has-addons">
      <p className="control is-expanded has-icons-left">
        <input
          value={value}
          onChange={(e) => setValue(e.target.value)}
          className="input"
          type="text"
          placeholder="Search"
        />
        <span className="icon is-left">
          <i className="fas fa-search" aria-hidden="true"></i>
        </span>
      </p>
      <p className="control">
        <button type="button" onClick={handleClearClick} className="button is-link is-outlined">
          <img style={{ height: '120%', color: '#ffe08a' }} src={crossIcon} alt="cross" />
        </button>
      </p>
    </div>
  );
};
