import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Team } from '../../../../types/Team';
import { SearchTextField } from '../../../SearchTextField';

type Props = {
  teams: Team[] | null;
  currentTeamId: string | null;
  setTeam: (teamId: string) => void;
};

export const TeamTab: React.FC<Props> = ({ teams, currentTeamId, setTeam }) => {
  const [searchBy, setSearchBy] = useState('');

  const filteredTeams = teams?.filter((team) => team.name.match(searchBy));

  const currentTeam = teams?.find((team) => team.id === currentTeamId);

  return (
    <div className="box">
      <p className="title is-4 has-text-info">User's team</p>

      <div className="block">
        <p className="title is-5">
          Current team:{' '}
          <span className="tag is-link is-light is-large">
            <Link to={'/teams/' + currentTeam?.id}>{currentTeam?.name}</Link>
          </span>
        </p>
      </div>

      <SearchTextField
        searchBy={searchBy}
        onSearch={setSearchBy}
        onResetSearchBy={() => setSearchBy('')}
      />

      <table className="table is-striped is-narrow is-hoverable">
        <thead>
          <tr>
            <th>Id</th>
            <th>Team name</th>
            <th>Set Team</th>
          </tr>
        </thead>
        <tbody>
          {filteredTeams &&
            filteredTeams.length > 0 &&
            filteredTeams.map((team) => (
              <tr style={{ display: team.id === currentTeamId ? 'none' : '' }} key={team.id}>
                <th>{team.id}</th>
                <td>{team.name}</td>
                <td>
                  <button
                    onClick={() => setTeam(team.id)}
                    type="button"
                    className="button is-small is-responsive is-info is-outlined"
                  >
                    Set team
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      {(!teams || !teams.length) && <p className="title is-5 has-text-link">User dont have any cards yet.</p>}
    </div>
  );
};
