import React from 'react';
import { Link } from 'react-router-dom';
import { PaymentSectionsTable } from '../../components/PaymentSectionsTable';

export const PaymentSectionsListPage: React.FC = () => {
  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <h1 className="title">Payment sections</h1>
        <Link to="/payment-sections/new" className="button is-primary">
          Create section
        </Link>
      </div>

      <PaymentSectionsTable />
    </>
  );
};
