import axios from 'axios';
import { enqueueSnackbar } from 'notistack';

export const useInterbankRates = () => {
  const getInterbankRates = async (invoiceDate?: string) => {
    try {
      const { data } = await axios.get('/api/payments/interbank-rates', {
        params: { date: invoiceDate },
      });
      return data
    } catch (e) {
      console.error(e);
      const err = e as Error;
      enqueueSnackbar(`Error get rates: ${err.message}`, { variant: 'error' });
    }
  };

  return {
    getInterbankRates
  }
}
