import type { Dispatch, Reducer } from 'react';
import type { Client } from '../../types/Client';
import { api } from '../../utils/fetch';
import { tryCatch } from '../../utils/tryCatch';

export type State =
  | { mode: 'initial' }
  | { mode: 'ready'; clients: Client[] }
  | { mode: 'loading' }
  | { mode: 'error'; error: string };

type Action = { type: 'loadStart' } | { type: 'loadSuccess'; clients: Client[] } | { type: 'loadError'; error: Error };

export const getInitialState = (): State => ({
  mode: 'initial',
});

export const reducer: Reducer<State, Action> = (state, action) => {
  if (action.type === 'loadStart') {
    return { mode: 'loading' };
  }
  if (action.type === 'loadSuccess') {
    return { mode: 'ready', clients: action.clients };
  }
  if (action.type === 'loadError') {
    return { mode: 'error', error: action.error.message };
  }
  return state;
};

type LoadClientsParams = {
  dispatch: Dispatch<Action>;
};

export const loadClients = async (params: LoadClientsParams) => {
  const { dispatch } = params;
  dispatch({ type: 'loadStart' });
  const [error, newClients] = await tryCatch(() => api.get<Client[]>('/clients'));
  if (error) {
    return dispatch({ type: 'loadError', error });
  }
  dispatch({ type: 'loadSuccess', clients: newClients });
};
