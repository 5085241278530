import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import CMSLogo from '../../media/ClientsCMS-logo.png';
import classNames from 'classnames';
import { NavbarItem } from './NavbarItem';

export const Navbar: React.FC = () => {
  const [mobileMenu, setMobileMenu] = useState(false);

  return (
    <nav
      style={{ borderBottom: '1px solid #cfcfcf' }}
      className="navbar"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="navbar-brand">
        <Link className="navbar-item" to="/">
          <img src={CMSLogo} alt="Clients CMS" />
        </Link>

        <a
          role="button"
          className={classNames('navbar-burger', {
            'is-active': mobileMenu,
          })}
          aria-label="menu"
          aria-expanded="false"
          data-target="navbarBasicExample"
          onClick={() => setMobileMenu(!mobileMenu)}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div
        id="navbarBasicExample"
        className={classNames('navbar-menu', {
          'is-active': mobileMenu,
        })}
      >
        <div className="navbar-start is-hidden-desktop" onClick={() => setMobileMenu(!mobileMenu)}>
          <NavbarItem to="/clients" text="Clients" />
          <NavbarItem to="/users" text="Users" />
          <NavbarItem to="/teams" text="Teams" />
          <NavbarItem to="/resellers" text="Resellers" />
          <NavbarItem to="/payment-sections" text="Payment sections" />
          <NavbarItem to="/costs" text="Costs" />
          <NavbarItem to="/products-prices" text="Products prices" />
          <NavbarItem to="/create-payment-link" text="Create Payment Link" />
          <NavbarItem to="/invites" text="Invites" />

          <NavLink
            className={({ isActive }) =>
              classNames('navbar-item', {
                'has-background-info-light has-text-info-dark': isActive,
              })
            }
            to="/invoices"
          >
            Invoices
          </NavLink>
        </div>
      </div>
    </nav>
  );
};
