import React, { ChangeEventHandler, FormEvent, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { api } from '../../utils/fetch';
import { Loader } from '../Loader';
import { TextInputField } from '../TextInputField';
import { ChargeTeamModal } from './ChargeTeamModal';
import { EditTeamBalanceModal } from './EditTeamBalanceModal';
import { TeamInfoTab } from './Tabs/TeamInfoTab';
import { CompanyInfoTab } from './Tabs/CompanyInfoTab';
import { TransactionsTab } from './Tabs/TransactionsTab';
import { PaymentsTab } from './Tabs/PaymentsTab';
import { CardsTab } from './Tabs/CardsTab';
import { NavTabs } from './Tabs/NavTabs';
import { Team } from '../../types/Team';
import { TeamUsersTab } from './Tabs/TeamUsersTab';
import { CostsTab } from './Tabs/CostsTab';
import { Cost } from '../../types/Cost';
import { enqueueSnackbar } from 'notistack';

export enum Tabs {
  UserInfoTab = 'UserInfoTab',
  CompanyInfoTab = 'CompanyInfoTab',
  PaymentsTab = 'PaymentsTab',
  TransactionsTab = 'TransactionsTab',
  CardsTab = 'CardsTab',
  TeamUsersTab = 'TeamUsersTab',
  CostsTab = 'CostsTab',
}

export const TeamForm: React.FC = () => {
  const { teamId } = useParams();
  const navigate = useNavigate();

  const [tab, setTab] = useState<Tabs>(Tabs.UserInfoTab);
  const [isPaymentModal, setIsPaymentModal] = useState(false);
  const [isBalanceModal, setIsBalanceModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [currentTeam, setCurrentTeam] = useState<Team>({
    id: '',
    name: '',
    picture: '',
    domain: '',
    region: '',
    employees: '',
    token: '',
    paymentType: '',
    isVatPayer: false,
    vatNumber: '',
    reseller: 'reseller-estonia',
    companyName: '',
    companyNumber: '',
    address: '',
    city: '',
    postalCode: '',
    currency: 'EUR',
    fullAddress: '',
    activeCard: '',
  });
  const [balances, setBalances] = useState([
    {
      amount: 0,
      currency: 'EUR',
    },
  ]);
  const [costs, setCosts] = useState<Cost[] | null>(null);

  const closePaymentModal = () => {
    setIsPaymentModal(false);
  };
  const closeBalanceModal = () => {
    setIsBalanceModal(false);
  };
  const setUserDomains = (domains: string[]) =>
    setCurrentTeam((prevState) => ({
      ...prevState,
      domains,
    }));
  const setUserActiveCard = (cardId: string) =>
    setCurrentTeam((prevState) => ({
      ...prevState,
      activeCard: cardId,
    }));

  const getTeamFromAPI = async (id: string) => {
    setIsLoading(true);

    try {
      const res = await api.get<Required<Team>>('/teams/' + id);
      const costs = await api.get<Cost[]>('/costs');
      const teamCosts = costs.filter((cost) => res.domains.includes(cost.domain));

      setCosts(teamCosts);
      setBalances(res.balances);
      setCurrentTeam(res);
    } catch (e) {
      console.error(e);
      const err = e as Error;
      enqueueSnackbar(`Failed to get team: ${err.message}`, { variant: 'error' });
    }

    setIsLoading(false);
  };

  const formSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!currentTeam.name) {
      enqueueSnackbar('Team name should not be empty', { variant: 'warning' });
      return;
    }

    setIsSaving(true);
    try {
      if (teamId === 'new') {
        await api.post('/teams', currentTeam);
        enqueueSnackbar('Created!', { variant: 'success' });
        navigate('/teams');
      } else {
        await api.patch('/teams/' + teamId, currentTeam);
        enqueueSnackbar('Saved!', { variant: 'success' });
        getTeamFromAPI(teamId || '');
      }
    } catch (e) {
      console.error(e);
      const err = e as Error;
      enqueueSnackbar(`Failed to save team: ${err.message}`, { variant: 'error' });
    }
    setIsSaving(false);
  };

  useEffect(() => {
    if (teamId && teamId !== 'new') {
      getTeamFromAPI(teamId);
    }
  }, []);

  const { name, activeCard, currency } = currentTeam;

  const handleChange: ChangeEventHandler<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement> = (e) => {
    const { name, value } = e.target;

    if (name === 'isVatPayer') {
      setCurrentTeam((prevState) => ({ ...prevState, isVatPayer: value === '+' }));
      return;
    }

    setCurrentTeam((prevState) => ({ ...prevState, [name]: value ? value : undefined }));
  };

  return isLoading ? (
    <Loader />
  ) : (
    <>
      {isPaymentModal && <ChargeTeamModal closeModal={closePaymentModal} />}

      {isBalanceModal && (
        <EditTeamBalanceModal closeModal={closeBalanceModal} balances={balances} currency={currency} />
      )}

      {teamId !== 'new' && <NavTabs activeTab={tab} onChangeTab={(tab) => setTab(tab)} />}

      <form onSubmit={formSubmit} className="container">
        {teamId === 'new' ? (
          <>
            <TextInputField
              name="name"
              label="Name:"
              value={name || ''}
              placeholder="Name input"
              onChange={handleChange}
            />
          </>
        ) : (
          <>
            {tab === Tabs.UserInfoTab && (
              <TeamInfoTab team={currentTeam} handleChange={handleChange} setDomains={setUserDomains} />
            )}

            {tab === Tabs.TeamUsersTab && <TeamUsersTab users={currentTeam.users} />}

            {tab === Tabs.CompanyInfoTab && <CompanyInfoTab team={currentTeam} handleChange={handleChange} />}

            {tab === Tabs.PaymentsTab && (
              <PaymentsTab
                team={currentTeam}
                handleChange={handleChange}
                balances={balances}
                onEditBalnce={() => setIsBalanceModal(true)}
                onChange={() => setIsPaymentModal(true)}
              />
            )}

            {tab === Tabs.CostsTab && <CostsTab costs={costs} />}

            {tab === Tabs.TransactionsTab && <TransactionsTab transactions={currentTeam.transactions} />}

            {tab === Tabs.CardsTab && (
              <CardsTab
                cards={currentTeam.cards}
                activeCard={activeCard || ''}
                onChangeActiveCard={setUserActiveCard}
              />
            )}
          </>
        )}

        <div className="field is-grouped">
          <div className="control">
            <button className="button is-primary">{isSaving ? <Loader /> : 'Save team'}</button>
          </div>
          <div className="control">
            <Link to="/teams" className="button is-light">
              Cancel
            </Link>
          </div>
        </div>
      </form>
    </>
  );
};
