import React, { ChangeEvent, FormEvent, useState } from 'react';
import classNames from 'classnames';
import { Loader } from '../Loader';
import { api } from '../../utils/fetch';
import { LogEntry } from '../../types/LogEntry';
import { enqueueSnackbar } from 'notistack';

export const GooglePricelistForm: React.FC = () => {
  const [pricelistFile, setPricelistFile] = useState<File | null>(null);
  const [isErrorPricelistFile, setIsErrorPricelistFile] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isValidationError, setIsValidationError] = useState(false);

  const fileUploadHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    if (e.target.files[0].type !== 'text/csv') {
      setIsErrorPricelistFile(true);
      return;
    }

    setIsErrorPricelistFile(false);
    setIsValidationError(false);
    setPricelistFile(e.target.files[0]);
  };

  const formSubmitHandler = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!pricelistFile || isErrorPricelistFile) {
      setIsErrorPricelistFile(true);
      setIsValidationError(true);
      return;
    }

    setIsLoading(true);

    const formData = new FormData();

    formData.append('pricelist', pricelistFile);

    try {
      await api.sendFiles<LogEntry[]>('/products-prices/from-csv', formData);

      setPricelistFile(null);
      enqueueSnackbar('File uploaded!', { variant: 'success' });
    } catch (e) {
      console.error(e);
      const err = e as Error;
      enqueueSnackbar(`File upload failed: ${err.message}`, { variant: 'error' });
    }

    setIsLoading(false);
  };

  return (
    <form className="form" onSubmit={formSubmitHandler}>
      <div className="field">
        <span className="label">'pricelist.csv' file</span>
        <div
          className={classNames('file has-name', {
            'is-danger': isErrorPricelistFile,
            'is-success': pricelistFile,
          })}
        >
          <label className="file-label">
            <input onChange={fileUploadHandler} className="file-input" type="file" accept=".csv" name="usages" />

            <span className="file-cta">
              <span className="file-icon">
                <i className="fas fa-upload"></i>
              </span>

              <span className="file-label">Choose a 'pricelist.csv' file</span>
            </span>

            <span className="file-name">{pricelistFile ? pricelistFile.name : 'No file yet'}</span>
          </label>
        </div>
        {isErrorPricelistFile && <p className="help is-danger">This file type is invalid</p>}
      </div>

      {isValidationError && <p className="help is-danger">PLease select pricelist.csv file to generate invoices!</p>}

      <button className="button is-info" type="submit">
        {isLoading ? <Loader /> : 'Send'}
      </button>
    </form>
  );
};
