import React, { useState } from 'react';
import { PaymentSection } from '../../types/PaymentSection';
import { api } from '../../utils/fetch';
import { enqueueSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Input, Select, Space } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { useResources } from './useResources';

const { Option } = Select;

type Props = {
  paymentSectionItem?: PaymentSection;
  isNewPaymentSection: boolean;
};

interface FormProps {
  language: string;
  country: string;
  currency: string;
  data: { label: string; value: string }[];
}

const languages = ['ENG', 'UA'];
const currencies = ['USD', 'UAH', 'EUR'];

export const PaymentSectionForm: React.FC<Props> = ({ paymentSectionItem, isNewPaymentSection }) => {
  const [isSaving, setIsSaving] = useState(false);
  const navigate = useNavigate();

  const createPaymentSectionOnAPI = async (newPaymentSection: FormProps) => {
    setIsSaving(true);

    try {
      await api.post<PaymentSection>('/payment-sections', newPaymentSection);

      enqueueSnackbar('Created!', { variant: 'success' });
    } catch (e) {
      const err = e as Error;
      enqueueSnackbar(`Failed to create payment section: ${err.message}`, { variant: 'error' });
    }

    navigate('/payment-sections');
    setIsSaving(false);
  };

  const savePaymentSectionUpdateOnAPI = async (newPaymentSection: FormProps) => {
    if (!paymentSectionItem) return;

    setIsSaving(true);

    try {
      await api.put<PaymentSection>('/payment-sections/' + paymentSectionItem.id, newPaymentSection);

      enqueueSnackbar('Saved!', { variant: 'success' });
    } catch (e) {
      const err = e as Error;
      enqueueSnackbar(`Failed to save payment section: ${err.message}`, { variant: 'error' });
    }

    navigate('/payment-sections');
    setIsSaving(false);
  };

  const onFinish = ({ currency, country, language, data }: FormProps) => {
    const newPaymentSection = {
      country,
      language,
      currency,
      data: data,
    };

    if (isNewPaymentSection) {
      createPaymentSectionOnAPI(newPaymentSection);

      return;
    }

    savePaymentSectionUpdateOnAPI(newPaymentSection);
  };

  return (
    <Form
      initialValues={paymentSectionItem || { data: [{ label: '', value: '' }] }}
      layout="vertical"
      style={{ maxWidth: 600 }}
      onFinish={onFinish}
      requiredMark={false}
    >
      <Form.Item label="Country" name="country" rules={[{ required: true, message: 'Required' }]}>
        <Input placeholder="Enter country" />
      </Form.Item>
      <Form.Item label="Language" name="language" rules={[{ required: true, message: 'Required' }]}>
        <Select placeholder="Select language" style={{ width: 217 }}>
          {languages.map((language) => (
            <Option key={language} value={language}>
              {language}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Currency" name="currency" rules={[{ required: true, message: 'Required' }]}>
        <Select placeholder="Select currency" style={{ width: 217 }}>
          {currencies.map((currency) => (
            <Option key={currency} value={currency}>
              {currency}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Data">
        <Form.List name="data">
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map(({ name, key, ...field }, index) => (
                <Space key={key} style={{ marginBottom: 10 }}>
                  <Form.Item
                    {...field}
                    name={[name, 'label']}
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: 'Please input label',
                      },
                    ]}
                    style={{ marginBottom: 10 }}
                  >
                    <Input placeholder="Label" />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    name={[name, 'value']}
                    rules={[
                      {
                        required: true,
                        message: 'Please input value',
                      },
                    ]}
                    style={{ marginBottom: 10 }}
                  >
                    <Input placeholder="Value" />
                  </Form.Item>
                  {fields.length > 1 ? (
                    <Button shape="circle" onClick={() => remove(name)} icon={<MinusOutlined />} />
                  ) : null}
                </Space>
              ))}
              <Form.Item>
                <Button icon={<PlusOutlined />} onClick={() => add()}>
                  Add row
                </Button>
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form.Item>
      <Form.Item>
        <Space>
          <Button type="primary" htmlType="submit" loading={isSaving}>
            Save payment section
          </Button>
          <Button htmlType="button" onClick={() => navigate('/payment-sections')}>
            Cancel
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};
