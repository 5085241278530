import React, { useEffect } from 'react';
import { config } from '../../config';
import { User } from '../../types/User';
import { api } from '../../utils/fetch';
import s from './LoginWindow.module.css';
import { enqueueSnackbar } from 'notistack';

type Props = {
  loginHandler: () => void;
};

export const LoginWindow: React.FC<Props> = ({ loginHandler }) => {
  useEffect(() => {
    const src = 'https://accounts.google.com/gsi/client';
    const id = config.googleClientId;

    async function handleCredentialResponse(response: any) {
      const idToken = response.credential;

      try {
        await api.post('/auth/gsi', { idToken });
        const user = await api.get<User>('/auth/me');

        if (user.adminRole !== 'user') {
          loginHandler();
        } else {
          enqueueSnackbar(`Not enough permissions to log in`, { variant: 'warning' });
        }
      } catch (e) {
        console.error(e);
        const err = e as Error;
        enqueueSnackbar(`Login failed: ${err.message}`, { variant: 'error' });
      }
    }

    const doInit = async () => {
      try {
        await loadScript(src);
        // @ts-ignore
        const google = window.google;

        google.accounts.id.initialize({
          client_id: id,
          callback: handleCredentialResponse,
        });
        google.accounts.id.renderButton(document.getElementById('gsi-button'), {
          type: 'standard',
          theme: 'filled_blue',
          size: 'large',
          width: 400,
        });
        google.accounts.id.prompt();
      } catch (error) {
        console.error(error);
        const err = error as Error;
        enqueueSnackbar(`Login failed: ${err.message}`, { variant: 'error' });
      }
    };

    void doInit();

    return () => {
      const scriptTag = document.querySelector(`script[src="${src}"]`);

      if (scriptTag) {
        document.body.removeChild(scriptTag);
      }
    };
  }, []);

  return (
    <section className="hero is-info is-fullheight">
      <div className="hero-body">
        <div className="container">
          <div className="columns is-centered">
            <div className={s.boxCenter}>
              <div className="box" style={{ width: 'max-content' }}>
                <div className="title has-text-info">Sign-In</div>
                <div className="googleGSI-button" id="gsi-button"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const loadScript = (src: string) =>
  new Promise((resolve, reject) => {
    if (document.querySelector(`script[src="${src}"]`)) {
      return resolve('');
    }

    const script = document.createElement('script');

    script.src = src;
    script.onload = () => resolve('');
    script.onerror = (err) => reject(err);
    document.body.appendChild(script);
  });
