import React from 'react';
import { PaymentSectionForm } from '../../components/PaymentSectionForm';

export const CreatePaymentSection: React.FC = () => {
  return (
    <>
      <h1 className="title">Create payment section</h1>

      <PaymentSectionForm isNewPaymentSection={true} />
    </>
  );
};
