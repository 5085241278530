import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ClientForm } from '../../components/ClientForm';
import { Loader } from '../../components/Loader';
import { Client } from '../../types/Client';
import { api } from '../../utils/fetch';
import { enqueueSnackbar } from 'notistack';

export const ClientPage: React.FC = () => {
  const { clientId } = useParams();

  const [client, setClient] = useState<Client | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getClientFromAPI = async () => {
      setIsLoading(true);

      try {
        const response: Client = await api.get<Client>('/clients/' + clientId);

        setClient(response);
      } catch (e) {
        console.error(e);
        const err = e as Error;
        enqueueSnackbar(`Failed to fetch clients: ${err.message}`, { variant: 'error' });
      }

      setIsLoading(false);
    };

    getClientFromAPI();
  }, [clientId]);

  return (
    <>
      <h1 className="title">Client page</h1>

      {client && <ClientForm client={client} isNewClient={false} />}

      {isLoading && <Loader />}
    </>
  );
};
