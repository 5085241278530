import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import type { Client } from '../../types/Client';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';

type ClientsTableProps = {
  clients: Client[];
  onDeleteClick: (id: number) => void;
};

export const ClientsTable: FC<ClientsTableProps> = ({ clients, onDeleteClick }) => {
  const columns: ColumnsType<Client> = [
    {
      title: 'Client',
      dataIndex: 'client',
      key: 'client',
      render: (_, client) => (
        <div>
          <strong>{client.billFrom || '<no name>'}</strong>
          <br />
          {(client.domains || []).join(', ')}
        </div>
      ),
    },
    {
      title: 'Reseller',
      dataIndex: 'reseller',
      key: 'reseller',
      render: (_, client) => (
        <div>
          {client.reseller || '—'}
        </div>
      ),
    },
    {
      title: 'Bill Currency',
      dataIndex: 'billCurrency',
      key: 'billCurrency',
    },
    {
      title: 'Actions',
      dataIndex: 'Actions',
      key: 'Actions',
      render: (_, client) => (
        <td className="has-text-centered">
          <Link to={`/clients/${client.id}`}>Edit</Link>
          <br />
          <button className="button is-ghost has-text-danger" onClick={() => onDeleteClick(client.id)}>
            Delete
          </button>
        </td>
      ),
    },
  ];
  return <Table dataSource={clients} columns={columns} pagination={false} />;
};
