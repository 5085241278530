import React from 'react';
import { NavbarItem } from '../Navbar/NavbarItem';
import clientsIcon from '../../media/recording-outline.svg';
import attachIcon from '../../media/attach-outline.svg';
import calendarIcon from '../../media/calendar-outline.svg';
import cashIcon from '../../media/cash-outline.svg';
import docIcon from '../../media/document-outline.svg';
import linkIcon from '../../media/link-outline.svg';
import teamsIcon from '../../media/people-outline.svg';
import usersIcon from '../../media/person-outline.svg';
import pricesIcon from '../../media/pricetags-outline.svg';
import walletIcon from '../../media/wallet-outline.svg';
import apertureIcon from '../../media/aperture-outline.svg';

export const NavSideBar: React.FC = () => {
  return (
    <aside
      style={{ paddingLeft: '10px', paddingTop: 0 }}
      className="column is-3 is-narrow-mobile is-fullheight section is-hidden-touch"
    >
      <p className="menu-label">Clients CMS Navigation:</p>
      <ul className="menu-list">
        <li>
          <NavbarItem icon={clientsIcon} to="/clients" text="Clients" />
        </li>
        <li>
          <NavbarItem icon={usersIcon} to="/users" text="Users" />
        </li>
        <li>
          <NavbarItem icon={teamsIcon} to="/teams" text="Teams" />
        </li>
        <li>
          <NavbarItem icon={docIcon} to="/resellers" text="Resellers" />
        </li>
        <li>
          <NavbarItem icon={walletIcon} to="/payment-sections" text="Payment sections" />
        </li>
        <li>
          <NavbarItem icon={cashIcon} to="/costs" text="Costs" />
        </li>
        <li>
          <NavbarItem icon={pricesIcon} to="/products-prices" text="Products prices" />
          <ul style={{ margin: 0 }}>
            <li>
              <NavbarItem icon={attachIcon} to="/products-prices/from-csv" text="From CSV" />
            </li>
          </ul>
        </li>
        <li>
          <NavbarItem icon={calendarIcon} to="/invoices" text="Invoices" />
          <ul style={{ margin: 0 }}>
            <li>
              <NavbarItem icon={attachIcon} to="/invoices/generate" text="Generate invoices" />
            </li>
          </ul>
        </li>
        <li>
          <NavbarItem icon={linkIcon} to="/create-payment-link" text="Create Pay-Link" />
        </li>
        <li>
          <NavbarItem icon={apertureIcon} to="/invites" text="Invites" />
        </li>
      </ul>
    </aside>
  );
};
