import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ProductsPrice } from '../../types/ProductPrice';
import { Button, DatePicker, Form, Input, InputNumber, Select, Space } from 'antd';
import { useForm } from 'antd/es/form/Form';
import dayjs from 'dayjs';
import { FormData } from './types';

const { Option } = Select;
const { RangePicker } = DatePicker;

const currencies = ['USD', 'EUR'];
const licenseTypes = ['Commitment', 'Usage'];

interface Props {
  loading: boolean
  defaultProductPrice?: ProductsPrice
  onFinish: (values: FormData) => Promise<void>
}

export const ProductPriceForm: FC<Props> = ({defaultProductPrice, onFinish, loading}) => {
  const navigate = useNavigate();

  const [form] = useForm<FormData>();

  useEffect(() => {
    if (!defaultProductPrice) return;

    const defaultFields: (keyof ProductsPrice)[] = [
      'product',
      'currency',
      'amount',
      'discountPercent',
      'type',
      'spendbaseFeePercent',
      'referralFeePercent',
    ];
    form.setFields([
      ...defaultFields.map((name) => ({ name, value: defaultProductPrice[name] })),
      {
        name: 'period',
        value: [dayjs(defaultProductPrice.validFrom), dayjs(defaultProductPrice.validTo)],
      },
    ]);
  }, [form, defaultProductPrice]);

  return <Form form={form} layout="vertical" style={{ maxWidth: 600 }} onFinish={onFinish} requiredMark={false}>
    <Form.Item label="Product" name="product" rules={[{ required: true, message: 'Required' }]}>
      <Input minLength={3} placeholder="Enter product name" />
    </Form.Item>
    <Form.Item label="Currency" name="currency" rules={[{ required: true, message: 'Required' }]}>
      <Select placeholder="Select currency" style={{ width: 150 }}>
        {currencies.map((currency) => (
          <Option key={currency} value={currency}>
            {currency}
          </Option>
        ))}
      </Select>
    </Form.Item>
    <Form.Item label="Standard price, license" name="amount" rules={[{ required: true, message: 'Required' }]}>
      <InputNumber min={1} placeholder="Enter price" style={{ width: 150 }} />
    </Form.Item>
    <Form.Item label="Discount, %" name="discountPercent" rules={[{ required: true, message: 'Required' }]}>
      <InputNumber min={0} max={100} placeholder="Enter discount" style={{ width: 150 }} />
    </Form.Item>
    <Form.Item label="Type of license" name="type" rules={[{ required: true, message: 'Required' }]}>
      <Select placeholder="Enter type" style={{ width: 150 }}>
        {licenseTypes.map((type) => (
          <Option key={type} value={type}>
            {type}
          </Option>
        ))}
      </Select>
    </Form.Item>
    <Form.Item label="SB Fee, %" name="spendbaseFeePercent" rules={[{ required: true, message: 'Required' }]}>
      <InputNumber min={0} max={100} placeholder="Enter fee percent" style={{ width: 150 }} />
    </Form.Item>
    <Form.Item label="Referral Fee, %" name="referralFeePercent" rules={[{ required: true, message: 'Required' }]}>
      <InputNumber min={0} max={100} placeholder="Enter fee percent" style={{ width: 150 }} />
    </Form.Item>
    <Form.Item label="Period" name="period" rules={[{ required: true, message: 'Required' }]}>
      <RangePicker picker="month" />
    </Form.Item>
    <Form.Item>
      <Space>
        <Button type="primary" htmlType="submit" loading={loading}>
          Save product price
        </Button>
        <Button htmlType="button" onClick={() => navigate('/products-prices')}>
          Cancel
        </Button>
      </Space>
    </Form.Item>
  </Form>
};

