import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ProductPriceForm } from '../../components/ProductPriceForm';
import { CreateProductPriceProps } from '../../types/ProductPrice';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { Loader } from '../../components/Loader';
import { FormData } from '../../components/ProductPriceForm/types';
import { useProductPrice } from './useProductPrice';
import dayjs from 'dayjs';

export const EditProductPrice: React.FC = () => {
  const { productPriceId } = useParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { defaultProductPrice, loading: getProductPriceLoading } = useProductPrice(productPriceId);

  const handleFinish = async (values: FormData) => {
    if (!defaultProductPrice) return;
    const [validFrom, validTo] = values.period;

    try {
      setLoading(true);
      const {
        data: { id: productId },
      } = await axios.patch<{
        id: string;
      }>('/api/products/' + defaultProductPrice.productId, { name: values.product });

      const productPrice: CreateProductPriceProps = {
        ...values,
        validFrom: dayjs(validFrom).startOf('m').toJSON(),
        validTo: dayjs(validTo).endOf('m').toJSON(),
        productId,
      };
      await axios.patch('/api/prices/' + productPriceId, productPrice);
      enqueueSnackbar('Product saved!', { variant: 'success' });
      navigate('/products-prices');
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const errorMessage = 'errors' in e.response?.data ? e.response?.data.errors[0] : e.response?.data.message;
        enqueueSnackbar(`Failed to create product price: ${errorMessage}`, { variant: 'error' });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h1 className="title">Edit Product price</h1>

      {getProductPriceLoading ? (
        <Loader />
      ) : (
        <ProductPriceForm defaultProductPrice={defaultProductPrice} onFinish={handleFinish} loading={loading} />
      )}
    </div>
  );
};
