import './App.scss';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ClientsPage } from './pages/ClientsPage';
import { CreateClient } from './pages/CreateClient';
import { Navbar } from './components/Navbar';
import { ClientPage } from './pages/ClientPage';
import { ResellersPage } from './pages/ResellersPage';
import { ResellerPage } from './pages/ResellerPage';
import { CreateReseller } from './pages/CreateReseller';
import { Footer } from './components/Footer';
import { CreateInvoice } from './pages/CreateInvoice';
import { PaymentSectionsListPage } from './pages/PaymentSectionsListPage';
import { CreatePaymentSection } from './pages/CreatePaymentSection';
import { PaymentSectionPage } from './pages/PaymentSectionPage';
import { LoginWindow } from './components/LoginWindow';
import { useEffect, useState } from 'react';
import { api } from './utils/fetch';
import { CostsPage } from './pages/CostsPage';
import { CreateCostPage } from './pages/CreateCostPage';
import { UsersPage } from './pages/UsersPage';
import { UserPage } from './pages/UserPage';
import { CreatePaymentLink } from './pages/CreatePaymentLink';
import { ProductsPricesPage } from './pages/ProductsPricesPage';
import { CreateProductPrice } from './pages/CreateProductPrice';
import { ProductsPricesFromGoogleCsv } from './pages/ProductsPricesFromGoogleCsv';
import { NavSideBar } from './components/NavSideBar';
import { TeamsPage } from './pages/TeamsPage';
import { TeamPage } from './pages/TeamPage';
import { User } from './types/User';
import { Invites } from './components/InvitesAndAdmins';
import { EditProductPrice } from './pages/EditProductPrice';
import { useZoom } from './hooks';
import { Invoices } from './pages/Invoices';

export function App() {
  const [login, setLogin] = useState(true);
  useZoom();

  const closeLogin = () => {
    setLogin(false);
  };

  useEffect(() => {
    const checkIfUserIsAdmin = async () => {
      try {
        const user = await api.get<User>('/auth/me');

        if (user.adminRole !== 'user') {
          setLogin(false);
        }
      } catch (e) {
        console.error('Sing-In please!');
        setLogin(true);
      }
    };

    checkIfUserIsAdmin();
  }, []);

  return login ? (
    <LoginWindow loginHandler={closeLogin} />
  ) : (
    <div>
      <header>
        <Navbar />
      </header>

      <main className="main container" style={{ marginTop: '12px' }}>
        <NavSideBar />

        <div style={{ width: '100%' }}>
          <Routes>
            <Route path="/clients" element={<ClientsPage />} />
            <Route path="/clients/new" element={<CreateClient />} />
            <Route path="/clients/:clientId" element={<ClientPage />} />

            <Route path="/resellers" element={<ResellersPage />} />
            <Route path="/resellers/new" element={<CreateReseller />} />
            <Route path="/resellers/:resellerId" element={<ResellerPage />} />

            <Route path="/payment-sections" element={<PaymentSectionsListPage />} />
            <Route path="/payment-sections/new" element={<CreatePaymentSection />} />
            <Route path="/payment-sections/:paymentSectionId" element={<PaymentSectionPage />} />

            <Route path="/costs" element={<CostsPage />} />
            <Route path="/costs/:costId" element={<CreateCostPage />} />

            <Route path="/products-prices" element={<ProductsPricesPage />} />
            <Route path="/products-prices/from-csv" element={<ProductsPricesFromGoogleCsv />} />
            <Route path="/products-prices/create" element={<CreateProductPrice />} />
            <Route path="/products-prices/:productPriceId" element={<EditProductPrice />} />

            <Route path="/users" element={<UsersPage />} />
            <Route path="/users/:userId" element={<UserPage />} />

            <Route path="/teams" element={<TeamsPage />} />
            <Route path="/teams/:teamId" element={<TeamPage />} />

            <Route path="/create-payment-link" element={<CreatePaymentLink />} />

            <Route path="/invites" element={<Invites />} />

            <Route path="/invoices" element={<Invoices />} />
            <Route path="/invoices/generate" element={<CreateInvoice />} />

            <Route path="*" element={<Navigate to="/clients" replace />} />
          </Routes>
        </div>
      </main>

      <Footer />
    </div>
  );
}
