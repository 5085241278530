import { Button, Table } from 'antd';
import React, { FC } from 'react';
import { ColumnsType } from 'antd/es/table';
import { Invoice, InvoiceStatus } from '../../../../types/Invoice';
import { StatusCell } from '../StatusCell';
import dayjs from 'dayjs';
import { ITEMS_PER_PAGE_LIMIT } from '../../hooks/useInvoiceTable';

interface Props {
  invoices: Invoice[];
  loading: boolean;
  currentPage: number;
  totalCount: number;
  onPaginationChange: (page: number) => void;
  onStatusChange: (invoiceId: string, status: InvoiceStatus) => void;
}

export const InvoiceTable: FC<Props> = ({
  invoices,
  loading,
  totalCount,
  currentPage,
  onPaginationChange,
  onStatusChange,
}) => {
  const columns: ColumnsType<Invoice> = [
    {
      title: 'Client Name',
      dataIndex: 'clientName',
      width: '50%',
      key: 'clientName',
    },
    {
      title: 'Date of Invoice',
      dataIndex: 'invoiceDate',
      width: '15%',
      key: 'invoiceDate',
      render: (invoiceDate) => dayjs(invoiceDate).format('YYYY-MM-DD'),
    },
    {
      title: 'Due Date',
      dataIndex: 'dueDate',
      width: '15%',
      render: (dueDate) => dayjs(dueDate).format('YYYY-MM-DD'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_, invoice) => <StatusCell invoice={invoice} onStatusChange={onStatusChange} />,
    },
    /*Note: for later: {
      title: '',
      dataIndex: '',
      key: '',
      render: (_, invoice) => <Button>Download PDF</Button>,
    },*/
  ];

  return (
    <Table
      loading={loading}
      dataSource={invoices}
      columns={columns}
      pagination={{
        current: currentPage,
        total: totalCount,
        pageSize: ITEMS_PER_PAGE_LIMIT,
        hideOnSinglePage: true,
        showSizeChanger: false,
        onChange: (page) => {
          onPaginationChange(page);
        },
      }}
    />
  );
};
