import { FC, memo } from 'react';
import { Link } from 'react-router-dom';
import type { Reseller } from '../../types/Reseller';

type ResellersTableProps = {
  resellers: Reseller[];
  onDeleteClick: (id: number) => void;
};

export const ResellersTable: FC<ResellersTableProps> = memo(({ resellers, onDeleteClick }) => (
  <div className="table-container">
    <table className="table is-hoverable is-fullwidth">
      <thead>
        <tr>
          <th>Reseller</th>
          <th>Info</th>
          <th className="has-text-centered">Actions</th>
        </tr>
      </thead>
      <tbody>
        {resellers.map((reseller) => (
          <tr key={reseller.id}>
            <td>
              <strong>{reseller.reseller}</strong>
            </td>
            <td style={{ whiteSpace: 'pre' }}>{reseller.resellerInfo}</td>
            <td className="has-text-centered">
              <Link to={`/resellers/${reseller.id}`}>Edit</Link>
              <br />
              <button className="button is-ghost has-text-danger" onClick={() => onDeleteClick(reseller.id)}>
                Delete
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
));
