import React, { FC } from 'react';
import { Dropdown, Typography } from 'antd';
import { Invoice, InvoiceStatus } from '../../../../types/Invoice';

interface Props {
  invoice: Invoice;
  onStatusChange: (invoiceId: string, status: InvoiceStatus) => void;
}

const statusNames = ['draft', 'pending', 'paid', 'overdue'] as const;

export const StatusCell: FC<Props> = ({ invoice, onStatusChange }) => {
  const onStatusClick = (status: InvoiceStatus) => () => onStatusChange(invoice.id, status);

  const statuses = statusNames.map((status) => ({
    key: status,
    label: status,
    onClick: onStatusClick(status),
  }));

  return (
    <Dropdown menu={{ items: statuses }} placement="bottomCenter" trigger={['click']}>
      <Typography style={{ cursor: 'pointer' }}>{invoice.status}</Typography>
    </Dropdown>
  );
};
