import React, { useEffect, useReducer, useState } from 'react';
import { Client } from '../../types/Client';
import { api } from '../../utils/fetch';
import { CreatePaymentLinkModal } from './PaymentLinkModal';
import { getTotal } from '../../utils/getTotalCost';
import { enqueueSnackbar } from 'notistack';
import { Button, Form, Input, Select, Space, Tooltip } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useNavigate } from 'react-router-dom';
import { CloseOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { getInitialState, loadResellers, reducer } from '../../pages/ResellersPage/reducer';
import { useForm } from 'antd/es/form/Form';

const { Option } = Select;

type Props = {
  client?: Client;
  isNewClient: boolean;
};

const currencies = ['USD', 'UAH', 'EUR'];
const languages = ['ENG', 'UA'];

export const ClientForm: React.FC<Props> = ({ client, isNewClient }) => {
  const [form] = useForm();
  const navigate = useNavigate();
  const [isSaving, setIsSaving] = useState(false);
  const [isPaymentModal, setIsPaymentModal] = useState(false);
  const [isNewClientSaved, setIsNewClientSaved] = useState(false);

  const [currentClient, setCurrentClient] = useState<Client | undefined>(client);

  const [state, dispatch] = useReducer(reducer, undefined, getInitialState);
  useEffect(() => {
    void loadResellers({ dispatch });
  }, []);

  const saveClientUpdateOnAPI = async (newClient: Client) => {
    setIsSaving(true);

    try {
      await api.put('/clients/' + newClient.id, newClient);

      enqueueSnackbar('Client updated!', { variant: 'success' });
      navigate('/clients');
    } catch (e) {
      const err = e as Error;
      enqueueSnackbar(`Failed to update client: ${err.message}`, { variant: 'error' });
    }
    setIsSaving(false);
  };

  const createClientOnAPI = async (newClient: Client) => {
    setIsSaving(true);

    try {
      await api.post<Client>('/clients', newClient);
      enqueueSnackbar('Client saved!', { variant: 'success' });
      navigate('/clients');
    } catch (e) {
      const err = e as Error;
      enqueueSnackbar(`Failed to create client: ${err.message}`, { variant: 'error' });
    }

    setIsNewClientSaved(true);
    setIsSaving(false);
  };

  const onFinish = (values: Client) => {
    const newClient = { ...currentClient, ...values };

    setCurrentClient(newClient);

    if (isNewClient) {
      createClientOnAPI(newClient);

      return;
    }

    saveClientUpdateOnAPI(newClient);
  };

  const closePaymentModal = () => {
    setIsPaymentModal(false);
  };

  const openPaymentModal = () => {
    if (!isNewClient) {
      setIsPaymentModal(true);
    } else {
      if (isNewClientSaved) {
        setIsPaymentModal(true);
      } else {
        enqueueSnackbar('To create payment link save a new client first', { variant: 'error' });
      }
    }
  };

  useEffect(() => {
    // set default values for fields that should not be empty
    if (!currentClient) return

    if (!currentClient.telegram) {
      form.setFieldValue('telegram', [''])
    }

    if (!currentClient.email) {
      form.setFieldValue('email', [''])
    }

    if (!currentClient.domains) {
      form.setFieldValue('domains', [''])
    }
  }, [currentClient, form]);

  const { cost, discountPercent, email, referralFee, sbFeePercent } = currentClient ?? {};

  return (
    <>
      {isPaymentModal && currentClient && (
        <CreatePaymentLinkModal
          closeModal={closePaymentModal}
          emails={email || []}
          amount={+getTotal({ cost, discountPercent, referralFee, sbFeePercent })}
          currency={currentClient.billCurrency}
        />
      )}
      <div className="field">
        <button onClick={openPaymentModal} className="button is-success">
          Create payment link
        </button>
      </div>
      <Form
        form={form}
        initialValues={currentClient || { domains: [''], email: [''], telegram: [''] }}
        layout="vertical"
        style={{ maxWidth: 600 }}
        onFinish={onFinish}
        requiredMark={false}
      >
        <Space>
          <Form.Item label="Reseller (optional)" name="reseller">
            <Select
              loading={state.mode === 'loading' || state.mode === 'initial'}
              placeholder="Select reseller"
              style={{ width: 217 }}
            >
              {state.mode === 'ready' &&
                state.resellers?.map((r) => (
                  <Option key={r.id} value={r.reseller}>
                    {r.reseller}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Tooltip title="Clear reseller">
            <Button shape="circle" onClick={() => form.setFieldValue('reseller', null)}>
              <CloseOutlined />
            </Button>
          </Tooltip>
        </Space>
        <Form.List name="domains">
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map((field, index) => (
                <Form.Item
                  label={index === 0 && 'Client’s domain'}
                  required={false}
                  key={field.key}
                  style={{ marginBottom: 10 }}
                >
                  <Space>
                    <Form.Item
                      {...field}
                      label="Client’s domain"
                      validateTrigger={['onChange', 'onBlur']}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: 'Required',
                        },
                      ]}
                      noStyle
                    >
                      <Input placeholder="domain name" />
                    </Form.Item>
                    {fields.length > 1 ? (
                      <Button shape="circle" onClick={() => remove(field.name)} icon={<MinusOutlined />} />
                    ) : null}
                  </Space>
                </Form.Item>
              ))}
              <Form.Item>
                <Button icon={<PlusOutlined />} onClick={() => add()}>
                  Add domain
                </Button>
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )}
        </Form.List>
        <Form.Item label="Client’s company name" name="billFrom" rules={[{ required: true, message: 'Required' }]}>
          <Input placeholder="Enter company name" />
        </Form.Item>
        <Form.Item label="Client’s billing address" name="address" rules={[{ required: true, message: 'Required' }]}>
          <TextArea placeholder="Enter billing address" rows={4} />
        </Form.Item>
        <Form.Item label="Invoice currency" name="billCurrency" rules={[{ required: true, message: 'Required' }]}>
          <Select placeholder="Select currency" style={{ width: 150 }}>
            {currencies.map((currency) => (
              <Option key={currency} value={currency}>
                {currency}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Invoice language" name="invoiceLanguage" rules={[{ required: true, message: 'Required' }]}>
          <Select placeholder="Select language" style={{ width: 150 }}>
            {languages.map((language) => (
              <Option key={language} value={language}>
                {language}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.List name="email">
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map((field, index) => (
                <Form.Item label={index === 0 && 'Email'} required={false} key={field.key} style={{ marginBottom: 10 }}>
                  <Space>
                    <Form.Item
                      {...field}
                      validateTrigger={['onChange', 'onBlur']}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: 'Required',
                        },
                      ]}
                      noStyle
                    >
                      <Input type="email" placeholder="Enter email" />
                    </Form.Item>
                    {fields.length > 1 ? (
                      <Button shape="circle" onClick={() => remove(field.name)} icon={<MinusOutlined />} />
                    ) : null}
                  </Space>
                </Form.Item>
              ))}
              <Form.Item>
                <Button icon={<PlusOutlined />} onClick={() => add()}>
                  Add email
                </Button>
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )}
        </Form.List>
        <Form.List name="telegram">
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map((field, index) => (
                <Form.Item
                  label={index === 0 && 'Other ways to contact the clients (phone number/ TG acc)'}
                  required={false}
                  key={field.key}
                  style={{ marginBottom: 10 }}
                >
                  <Space>
                    <Form.Item
                      {...field}
                      validateTrigger={['onChange', 'onBlur']}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: 'Required',
                        },
                      ]}
                      noStyle
                    >
                      <Input placeholder="Contact name" />
                    </Form.Item>
                    {fields.length > 1 ? (
                      <Button shape="circle" onClick={() => remove(field.name)} icon={<MinusOutlined />} />
                    ) : null}
                  </Space>
                </Form.Item>
              ))}
              <Form.Item>
                <Button icon={<PlusOutlined />} onClick={() => add()}>
                  Add contact
                </Button>
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )}
        </Form.List>
        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit" loading={isSaving}>
              Save client
            </Button>
            <Button htmlType="button" onClick={() => navigate('/clients')}>
              Cancel
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </>
  );
};
