export const tryCatch = async <Fn extends (...args: any[]) => any>(
  fn: Fn
): Promise<[Error, undefined] | [undefined, Awaited<ReturnType<typeof fn>>]> => {
  try {
    const res = await fn();
    return [undefined, res];
  } catch (error) {
    return [error as Error, undefined];
  }
};

export const tryCatchSync = <Fn extends (...args: any[]) => any>(
  fn: Fn
): [Error, undefined] | [undefined, ReturnType<typeof fn>] => {
  try {
    const res = fn();
    return [undefined, res];
  } catch (error) {
    return [error as Error, undefined];
  }
};
