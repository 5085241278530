import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Cost } from '../../../../types/Cost';
import { currencySymbol } from '../../../../utils/getCurrencySymbol';
import { SearchTextField } from '../../../SearchTextField';

type Props = {
  costs: Cost[] | null;
};

export const CostsTab: React.FC<Props> = ({ costs }) => {
  const [searchBy, setSearchBy] = useState('');

  const filteredCosts = costs?.filter(
    (cost) =>
      cost.domain.toLowerCase().match(searchBy.toLowerCase()) ||
      cost.product.toLowerCase().match(searchBy.toLowerCase())
  );

  return (
    <div className="box">
      <p className="title is-4 has-text-info">User's team</p>

      <SearchTextField
        searchBy={searchBy}
        onSearch={(str) => setSearchBy(str)}
        onResetSearchBy={() => setSearchBy('')}
      />

      <table className="table is-striped is-narrow is-hoverable">
        <thead>
          <tr>
            <th>Id</th>
            <th>Domain</th>
            <th>Product</th>
            <th>Cost</th>
            <th>Discount</th>
            <th>View cost</th>
          </tr>
        </thead>
        <tbody>
          {filteredCosts &&
            filteredCosts.length > 0 &&
            filteredCosts.map((cost) => (
              <tr key={cost.id}>
                <th>{cost.id}</th>
                <td>{cost.domain}</td>
                <td>{cost.product}</td>
                <td>{`${currencySymbol[cost.currency]} ${cost.clientCost}`}</td>
                <td>{cost.discountPercent}%</td>
                <td>
                  <Link to={'/costs/' + cost.id}>Open</Link>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      {(!costs || !costs.length) && <p className="title is-5 has-text-link">Team dont have any costs yet.</p>}
    </div>
  );
};
