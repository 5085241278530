import { FC, useCallback, useEffect, useReducer, useState } from 'react';
import { Link } from 'react-router-dom';
import { ResellersTable } from './ResellersTable';
import { reducer, getInitialState, loadResellers } from './reducer';
import { api } from '../../utils/fetch';
import { DeletePrompt } from '../../components/DeletePrompt';

export const ResellersPage: FC = () => {
  const [state, dispatch] = useReducer(reducer, undefined, getInitialState);
  const [idToDel, setIdToDel] = useState<number | null>(null);

  const openPromptOnDel = (id: number) => setIdToDel(id);

  useEffect(() => {
    void loadResellers({ dispatch });
  }, []);

  const handleDeleteClick = async () => {
    await api.delete(`/resellers/${idToDel}`);
    await loadResellers({ dispatch });
    setIdToDel(null);
  };

  if (state.mode === 'initial' || state.mode === 'loading') {
    return <h1 className="title">Loading resellers...</h1>;
  }

  if (state.mode === 'error') {
    return (
      <>
        <h1 className="title">Resellers</h1>
        <p>There was an error with loading resellers, please refresh the page.</p>
        <p>{state.error}</p>
      </>
    );
  }

  return (
    <>
      {idToDel && (
        <DeletePrompt
          title="Are you sure?"
          buttons={
            <>
              <button onClick={handleDeleteClick} className="button mr-3 is-danger">
                Delete
              </button>

              <button onClick={() => setIdToDel(null)} className="button is-link">
                Close
              </button>
            </>
          }
        />
      )}
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <h1 className="title">Resellers</h1>
        <Link to="/resellers/new" className="button is-primary">
          Create reseller
        </Link>
      </div>
      <ResellersTable resellers={state.resellers} onDeleteClick={openPromptOnDel} />
    </>
  );
};
