import React, { FormEvent, useState } from 'react';
import { Reseller } from '../../types/Reseller';
import { api } from '../../utils/fetch';
import { Loader } from '../Loader';
import { enqueueSnackbar } from 'notistack';
import { Link, useNavigate } from 'react-router-dom';

type Props = {
  resellerItem: Reseller;
  isNewReseller: boolean;
};

export const ResellerForm: React.FC<Props> = ({ resellerItem, isNewReseller }) => {
  const [isSaving, setIsSaving] = useState(false);
  const navigate = useNavigate()

  const [currentReseller, setCurrentReseller] = useState<Reseller>(resellerItem);

  const createResellerOnAPI = async () => {
    setIsSaving(true);

    try {
      await api.post<Reseller>('/resellers', currentReseller);

      enqueueSnackbar('Created!', { variant: 'success' });
      navigate('/resellers')
    } catch (e) {
      const err = e as Error;
      enqueueSnackbar(`Failed to create reseller: ${err.message}`, { variant: 'error' });
    }

    setIsSaving(false);
  };

  const saveResellerUpdateOnAPI = async () => {
    setIsSaving(true);

    try {
      await api.put<Reseller>('/resellers/' + currentReseller.id, currentReseller);

      enqueueSnackbar('Saved!', { variant: 'success' });
      navigate('/resellers')
    } catch (e) {
      const err = e as Error;
      enqueueSnackbar(`Failed to save reseller: ${err.message}`, { variant: 'error' });
    }
    setIsSaving(false);
  };

  const formSubmitHandler = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (isNewReseller) {
      createResellerOnAPI();

      return;
    }

    saveResellerUpdateOnAPI();
  };

  const { reseller, resellerInfo } = currentReseller;

  return (
    <form onSubmit={formSubmitHandler} className="container">
      <div className="field">
        <label className="label">Reseller:</label>
        <div className="control">
          <input
            className="input"
            type="text"
            placeholder="Reseller input"
            value={reseller}
            onChange={(e) => setCurrentReseller((prevState) => ({ ...prevState, reseller: e.target.value }))}
          />
        </div>
      </div>

      <div className="field">
        <label className="label">Reseller info:</label>
        <div className="control">
          <textarea
            className="textarea"
            placeholder="Reseller info input"
            value={resellerInfo}
            onChange={(e) => setCurrentReseller((prevState) => ({ ...prevState, resellerInfo: e.target.value }))}
          ></textarea>
        </div>
      </div>

      <div className="field is-grouped">
        <div className="control">
          <button className="button is-primary">{isSaving ? <Loader /> : 'Save reseller'}</button>
        </div>
        <div className="control">
          <Link to="/resellers" className="button is-light">
            Cancel
          </Link>
        </div>
      </div>
    </form>
  );
};
